/**
 * Created by yosbel on 18/12/18.
 */
import ax from 'axios'
import config from '../helpers/config'
const axios = ax.create({
    timeout: 0,
    baseURL: config.apiUrl,
    url: config.apiUrl,
    headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('smpp'))
    }

});
const errorHandler = (error) => {
    const {response} = error
    if (response && response.status === 400 && response.data.code) {
        return Promise.reject(response.data.code)
    }else if(response && response.data.code === 1003){
        localStorage.removeItem('smpp');
        localStorage.clear();
        return Promise.reject(response.data.code)
    }

    let statusCode = response && response.status ? response.status : 0;

    return Promise.reject(statusCode)
}

const successHandler = (response) => {
    if (response.status != "200") {
        let res = response.response;
        let data = res.data;
        return data
    }
    if (response.data && response.data.token) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.token
    }

    if (response.data && response.data && response.data) {
        return response.data;
    }

    return '401';
}
axios.interceptors.request.use(function (config) {
    config.headers.Authorization = 'Bearer ' + JSON.parse(localStorage.getItem('smpp'))
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

axios.interceptors.response.use(
    response => successHandler(response),
    request => errorHandler(request),
    error => errorHandler(error)
)


export default axios





