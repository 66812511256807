
const messages = {
  es: {
    landing: {
      fees: 'Costos',
      benefits: 'Beneficios',
      contact: 'Contacto',
      subtitle: 'Use su CactusCard en <strong> en cualquier momento y en cualquier lugar </strong> <br> cajeros automáticos y tiendas en línea',
      buy: 'Comprar en todo el mundo',
      buy_subtitle: 'Es una tarjeta de débito prepaga aceptada en todo el mundo.',
      withdraw:'<strong> Retirar </strong> efectivo en cajeros automáticos',
      withdraw_subtitle:'Puede retirar efectivo de cualquier cajero automático en cualquier lugar.',
      pay:'<strong> Pagar </strong> en línea',
      pay_subtitle:'Compre productos y servicios en cualquier tienda en línea donde se acepte MasterCard.',
      start_today: '¡COMIENZA HOY!',
      plastic_or_virtual: "Plástico o virtual. Tu decides.",
      plastic: "Plástica",
      plastic_subtitle1: "Como cualquier otra tarjeta regular, obtienes el plástico para transacciones en línea, fuera de línea y en cajeros automáticos.",
      plastic_subtitle2: "¡La <b> tarjeta llegará </b> por correo dentro de los 10 días hábiles lista para ser activada!",
      plastic_want: "QUIERO ESTA TARJETA",
      virtual: "Virtual",
      virtual_subtitle1: "La tarjeta virtual no tiene forma física, lo que significa que solo puede usarla para pagos en línea. Recibirá los detalles de su tarjeta (número de tarjeta, fecha de vencimiento y CVV) por correo electrónico.",
      virtual_subtitle2: "Puede comenzar a usar su tarjeta virtual tan pronto como la reciba en su correo electrónico. A diferencia del plástico, no hay necesidad de esperar la entrega.",
      virtual_want: "QUIERO ESTA TARJETA",
      benefits1: '<li>No se requieren cuentas bancarias o historiales de crédito a los titulares de las tarjetas.</li><br>' +
          '<li>Control y gestión de sus pagos mediante una consola de administración online fácil e intuitiva.</li><br>' +
          '<li>Fondos disponibles en tan sólo unos minutos posterior a su recarga.</li>',
      benefits2: '<li>Menores gastos operativos y una mayor eficiencia en pagos electrónicos.</li><br>' +
          '<li>Asistencia por correo electrónico / telefónica / chat online. Las 24 horas.</li><br>' +
          '<li>Acceso a los fondos a través de cajeros automáticos y puntos de venta en cualquier momento y en cualquier parte del mundo.</li>',
      benefits3: '<li>Estructura de pagos sencilla y transparente.</li><br>' +
          '<li>Servicio de Banca para recibir y enviar transferencias intencionales sin límites.</li><br>' +
          '<li>Servicio de traspaso de saldo (Paypal, Payoneer, Western Union, etc) Consúltenos.</li>' +
          '<li>Posibilidad de logo propio para Compañías que requieran volúmen de Tarjetas.</li>',
      havent_card: "¿Todavía no tengo una CactusCard?",
      company: "Compañía",
      terms_label: "Términos y Condiciones",
      privacy_label: "Políticas de Privacidad Policy",
      policy_label: "Política Anti Lavado",
      help: "Ayuda y Soporte",
      begin: "COMIENZA HOY",
      faq: "Preguntas Frecuentes",
      policytitle: 'Política Anti Lavado',
      policy: '<strong>POLITICAS ANTI-BLANQUEO DE CAPITALES</strong><br>' +
          'Podría ocurrir que se le requiera que confirme su identidad y otros detalles personales. Guareschi y Paz SA de CV, tiene sistemas de verificación especiales establecidos incluyendo su cuenta bancaria.<br>' +
          'El robo de datos para los fines de suplantación de identidad, destinados a la financiación del crimen organizado, es cada vez mayor, debido al gran volumen de información que se envía en internet, y que en muchos casos es interceptada por los criminales para sus objetivos.<br>' +
          'Los países que están al frente de la lucha contra el blanqueo de capitales son principalmente los gobiernos de los países de la UE y Estados Unidos y todas las organizaciones participantes en los servicios financieros. Todos están unidos determinantemente en acabar con la generación de crímenes financieros, como parte del esfuerzo internacional de acabar con las operaciones fraudulentas que persiguen la financiación de mafias, organizaciones criminales y terroristas, con el objetivo común de proteger a la población mundial del terrorismo y el crimen organizado.<br>' +
          'Su cooperación es vital para la lucha con éxito contra el crimen y el terrorismo, lo cual, habitualmente se requiere en la mayoría de legislaciones del mundo.<br>' +
          'Se nos requiere que mantengamos registro total de todas las transacciones junto a las identificaciones proveídas.<br>' +
          'Se nos requiere supervisar cualquier transacción sospechosa o no usual de cualquier cantidad.<br>' +
          'Tenemos una obligación legal de informar a la Agencia de Crímenes sobre transacciones sospechosas.<br>' +
          '<strong>DECLARACIÓN SOBRE AML, CFT Y KYC</strong><br>' +
          'Las Instituciones financieras deben poder identificar y entender los riesgos del lavado de dinero y financiamiento al terrorismo para poder aplicar medidas preventivas. Anti-Money Laundering (AML) y Counter Terrorist Financing (CTF) son áreas prioritarias de atención en Guareschi y Paz SA de CV.<br>' +
          'Guareschi y Paz SA de CV, evalúa continuamente la fortaleza y relevancia de nuestras políticas existentes, así como procedimientos y capacitación a empleados. El lavado de dinero y el terrorismo no solo dañan al público en general, sino que también pueden dañar la estabilidad y reputación de los sectores financieros. Resulta conveniente para nosotros tomar todas las medidas razonables para prevenir el lavado de dinero y financiamiento de terrorismo.<br>' +
          'Guareschi y Paz SA de CV, se adhiere y cumple al pie de la letra con todas las leyes de cumplimiento KYC (Conozca a su Cliente) y AML (Anti Lavado de Dinero).<br>' +
          'Dado que los criminales requieren de servicios financieros para disimular sus operaciones y auspiciar actividades criminales, las prácticas Conozca a Su Cliente (KYC) son importantes tanto para nosotros como para toda la base de clientes de Guareschi y Paz SA de CV<br>' +
          'Guareschi y Paz SA de CV, usa herramientas específicas para detectar transacciones sospechosas, tales como transacciones que no se alinean al perfil conocido del cliente. La detección de transacciones sospechosas, activa investigaciones a profundidad de la cuenta de nuestro cliente por parte de nuestra División de Cumplimiento. Guareschi y Paz SA de CV, tiene total libertad para terminar una relación existente de cliente. El Departamento de Cumplimiento de Guareschi y Paz SA de CV, tiene la potestad y arbitrariedad legal para reportar las transacciones sospechosas a las autoridades competentes.<br>' +
          'No se puede transferir dinero hacia o desde países considerados como no-cooperativos sin previa aprobación del Departamento de Cumplimiento de Guareschi y Paz SA de CV. La aprobación solo será dada a partir de documentación apropiada y el posterior análisis exhaustivo de los antecedentes económicos, así como la debida justificación de la transacción en cuestión.<br>' +
          'Para ayudar a los gobiernos a rastrear organizaciones criminales, traficantes de narcóticos, terroristas y de lavado de dinero, Guareschi y Paz SA de CV, consulta listas y bases de datos actualizadas con frecuencia, para el cumplimiento de la ley.<br>' +
          'Debido a las Políticas contra Lavado de Dinero y Políticas contra Financiamiento de Terrorismo, es de vital importancia que las instituciones financieras actúen para combatir los riesgos del lavado de dinero y asistan a los gobiernos en la identificación de potenciales actividades de auspicio al terrorismo. A continuación resumimos algunas de las políticas que seguimos en Guareschi y Paz SA de CV:<br>' +
          '– Nuestra empresa ha adoptado estándares de políticas internacionales en la prevención del lavado de dinero y en contra del financiamiento al terrorismo que requiere que la oficina principal de Guareschi y Paz SA de CV, sucursales extranjeras y subsidiarias en todo el mundo implementen y se adecúen a programas efectivos para cumplir con las leyes relevantes. El Departamento Legal & Compliance se esfuerza para que las Políticas de Guareschi y Paz SA de CV, se apliquen apropiadamente<br>' +
          '-Hemos establecido controles, regulaciones y procedimientos de cumplimiento de AML apropiados.<br>' +
          'Guareschi y Paz SA de CV, cuenta con procedimientos y políticas establecidas acerca de “Conozca a Su Cliente”. Verificamos la identidad del cliente a través de documentos, datos o información.<br>' +
          'Hemos desarrollado y continuamos actualizando el marco regulatorio y procedimientos que cumplan o excedan los requerimientos legales y regulatorios aplicables, y educamos continuamente a nuestros empleados;<br>' +
          'Todas estas medidas se llevan a cabo de acuerdo a las disposiciones de leyes y Tratados Internacionales, junto con los estándares y regulaciones financieras reconocidas internacionalmente.<br>' +
          'Agradecemos su estimada colaboración y rogamos disculpen las molestias que podamos ocasionar en algún momento, por el cumplimiento de las citadas Políticas Preventivas.<br> <br>',
      privacytitle: 'Políticas de Privacidad',
      privacy: '<strong>POLÍTICA DE PRIVACIDAD Y EXENCIÓN DE RESPONSABILIDAD de Guareschi y Paz SA de CV</strong><br>' +
          'El presente documento digital se constituye como parte de los acuerdos y términos de uso de Guareschi y Paz SA de CV, y tiene naturaleza jurídica de contrato como anexo del contrato de servicios (Acuerdo de Términos de uso de la plataforma Guareschi y Paz SA de CV).<br>' +
          '<strong>IDENTIDAD Y DIRECCION DEL TITULAR DE LOS SERVICIOS</strong><br>' +
          'Guareschi y Paz SA de CV (en adelante denominado “La Compañía” tiene el compromiso de cumplir con una política de confidencialidad y seguridad de los datos de carácter personal de sus clientes (En adelante “cliente/s”, “usuario/s” o “titular de los datos”) y se compromete a proteger su privacidad y cumplir con la legislación aplicable en materia de protección de datos de carácter personal. Guareschi y Paz SA de CV es responsable de solicitar y custodiar su información personal.<br>' +
          'En cumplimiento de las diferentes legislaciones aplicables, Guareschi y Paz SA de CV declara ser titular de un fichero de datos alojado en un servidor de seguridad cifrada.<br>' +
          '<strong>INFORMACIÓN PERSONAL SOLICITADA POR Guareschi y Paz SA de CV</strong><br>' +
          'Los datos personales que puede solicitar Guareschi y Paz SA de CV incluyen (I) los nombres, números del documento de identidad o pasaporte, direcciones, números de teléfono, correos electrónicos, fechas de nacimiento, información de pago medios digitales o virtuales de pago, (II) Como usuario de nuestros servicios, toda la información que usted ha proporcionado y/o proporcione en el futuro a Guareschi y Paz SA de CV para el mantenimiento de la relación de negocios, (III) los datos relacionados con los servicios protegidos proporcionados a Guareschi y Paz SA de CV en el cumplimiento de sus obligaciones, ya sea por razón de la prestación de los servicios o venta de productos, servicios y en general cualquier otra información que se pueda solicitar para la prestación de servicios adicionales que Guareschi y Paz SA de CV pueda poner a disposición en un futuro.<br>' +
          'Se presume que los datos proporcionados por los usuarios son verdaderos, correctos, completos, e identifican al titular de la cuenta de servicios proveídos por Guareschi y Paz SA de CV, por lo que es responsabilidad del titular de los datos la actualización de los mismos, si estos sufrieran alguna variación durante el contrato de servicio con Guareschi y Paz SA de CV. En ese sentido, el usuario está obligado a proporcionar a Guareschi y Paz SA de CV los datos actualizados en la forma requerida. Del mismo modo, Guareschi y Paz SA de CV se reserva el derecho de tomar las medidas que considere pertinentes si los datos proporcionados por el usuario son falsos, inexactos o incompletos.<br>' +
          '<strong>LIMITACIÓN DE USO Y DIVULGACIÓN</strong><br>' +
          'El usuario autoriza a Guareschi y Paz SA de CV a enviarle información de sus promociones, información de programas especiales, ofertas y servicios por correo electrónico. Ocasionalmente, Guareschi y Paz SA de CV podrá enviar promociones de otras empresas asociadas. En ese sentido, Guareschi y Paz SA de CV se compromete a la no divulgación de los datos del usuario y a no venderlos o cederlos a terceros (*). Este tipo de publicidad se realiza a través de boletines y mensajes promocionales por medio de correo electrónico. El usuario tiene derecho a declinar el envío de promociones de terceros y puede solicitar su baja en los boletines de ciertas promociones que no sean de su interés, para lo cual, Guareschi y Paz SA de CV incluirá en cada email un enlace de des-suscripción automática.<br>' +
          'Para el uso de cookies, el botón “Ayuda” que se encuentra en la barra de herramientas de la mayoría de los navegadores le dirá cómo evitar aceptar nuevos cookies, cómo hacer que el navegador le notifique cuando reciba una nueva cookie o cómo deshabilitar todos los cookies.<br>' +
          '(*) Cesión de Datos personales a terceros:<br>' +
          'Guareschi y Paz SA de CV sólo podrá ceder los datos personales de los usuarios a sus filiales y/o compañías afiliadas, ya sean compañías nacionales o extranjeras.<br>' +
          'Así mismo, Guareschi y Paz SA de CV podrá proporcionar algunos datos a las empresas con la que mantiene acuerdos de negocio para el cumplimiento de los servicios proporcionados.<br>' +
          'Guareschi y Paz SA de CV no proporcionará nunca sus datos a cualquier autoridad competente u organismo gubernamental, salvo que Guareschi y Paz SA de CV sea obligado judicialmente por autoridad competente, aunque así lo solicitasen ministerialmente.<br>' +
          'Para la realización de los fines descritos en el párrafo anterior, la transferencia o cesión de sus datos personales se limita a los actos, hechos y/o poner en práctica los procedimientos necesarios para que Guareschi y Paz SA de CV pueda efectuar y cumplir con sus obligaciones contractuales, regulatorios y/o de negocios en el curso normal de sus operaciones. Si el usuario tiene objeciones a que sus datos personales sean transferidos y/o cedidos, se considera que deberá extinguir su contrato de servicio con Guareschi y Paz SA de CV, dada la imposibilidad de cumplir con las obligaciones de servicio con bancos proveedores y otros.<br>' +
          '<strong>DATOS PERSONALES SENSIBLES</strong><br>' +
          'Guareschi y Paz SA de CV no solicita datos personales sensibles en ningún momento. (Origen racial o étnico, estado de salud, información genética, creencias religiosas, afiliación sindical, creencias filosóficas y morales, opiniones políticas, preferencia sexual u otros de naturaleza privada).<br>' +
          '<srong>MEDIOS PARA EJERCER LOS DERECHOS DE ACCESO, RECTIFICACIÓN, CANCELACIÓN U OPOSICIÓN</srong><br>' +
          'Guareschi y Paz SA de CV tiene los recursos técnicos, materiales y humanos para asegurar que sus datos personales serán tratados en estricto cumplimiento de la ley en cualquier momento, el usuario tendrá derecho a solicitar a Guareschi y Paz SA de CV el ejercicio de derechos en virtud de la Ley, mediante el envío de una solicitud por escrito a la siguiente dirección de correo electrónico: info@cactusprepaid.com. Se entiende que para que Guareschi y Paz SA de CV pueda abordar adecuadamente y con prontitud la solicitud a la que se refiere este artículo, la solicitud del titular debe contener al menos: (I) Nombre completo del titular de los datos, (II) el número de identificación, (III) una breve descripción del objeto de la solicitud, y (IV) razones de su solicitud. Nuestra respuesta a su solicitud será enviada en el plazo fijado por la ley para enviar por correo al remitente del mismo. Usted reconoce y acepta que uno de los fines del tratamiento de sus datos personales es cumplir las obligaciones comerciales que mantiene con Guareschi y Paz SA de CV, por lo que no se puede cancelar ni oponerse al tratamiento de datos personales que puedan afectar y/o restringir la aplicación de la misma, salvo que desee cancelar su cuenta de servicio con Guareschi y Paz SA de CV.<br>' +
          '<strong>MODIFICACIONES DEL PRESENTE DOCUMENTO</strong><br>' +
          'Guareschi y Paz SA de CV se reserva expresamente el derecho, a su sola discreción, de cambiar, modificar, añadir o eliminar partes de estas políticas de privacidad en cualquier momento. En tal caso, Guareschi y Paz SA de CV publicará en el sitio web www.cactusprepid.com un aviso con las modificaciones a practicar, así como una comunicación por medio de correo electrónico.<br>' +
          'En las presentes políticas de privacidad se indicará siempre la fecha de la última actualización. En la medida que el usuario, no exprese su oposición a las modificaciones efectuadas, puestas en su conocimiento y continúe el acceso y/o uso, en parte o en su totalidad, de los Servicios proporcionados por Guareschi y Paz SA de CV significará que ha aceptado y consentido tales cambios y/o modificaciones.<br>' +
          'Al proporcionar sus datos personales a Guareschi y Paz SA de CV, usted reconoce y acepta expresamente que acepta estas políticas de privacidad, y que las mismas podrán ser modificadas o ajustadas ocasionalmente, y su consentimiento para que Guareschi y Paz SA de CV continúe con el tratamiento de sus datos personales en los términos descritos en el presente acuerdo. Si usted no acepta estos términos, puede ejercer sus derechos bajo la ley, como se describió anteriormente<br>' +
          'Cabe señalar que en la web www.cactusprepaid.com, y/o en los productos y/o servicios de Guareschi y Paz SA de CV pueden aparecer enlaces a otros sitios web, hacia las aplicaciones que utilizan las plataformas y servicios de otras compañías que mantienen contratos de servicio con Guareschi y Paz SA de CV y que tienen sus propias políticas de privacidad. Si usted desea conocer sobre las directivas de terceras empresas suministradoras o asociadas, le sugerimos que nos consulte con el fin de tener conocimiento de las mismas.<br>' +
          '<strong>PROTECCIÓN DE DATOS Y SEGURIDAD DE SISTEMAS</strong><br>' +
          'La seguridad y confidencialidad de los datos que los usuarios proporcionan a la hora de contratar un servicio o comprar un producto en línea están protegidos por un servidor seguro en el protocolo Secure Socket Layer (SSL), de modo que los datos enviados se transmiten de forma encriptada para garantizar su privacidad.<br>' +
          'Para verificar que se encuentra en un entorno protegido asegúrese de que aparezca en la barra de navegación el protocolo de seguridad cifrada que se identifica con: https://. Sin embargo, y a pesar de contar con las herramientas más seguras, la protección de los datos enviados a través de Internet no se puede garantizar al 100%, por lo que, Guareschi y Paz SA de CV revisará constantemente los sistemas e intentará, en la medida de sus posibilidades, adaptar nuevos sistemas y herramientas que mejoren las prestaciones y la seguridad de la plataforma.<br>' +
          'Lo expuesto anteriormente no significa en modo alguna ninguna garantía y el usuario es consciente de que debe extremar las precauciones en su equipo local, en cuanto a virus, espionaje, Phishing y protección de las contraseñas se refiere.<br>' +
          'Mediante la presente el usuario es consciente de sus obligaciones en materia de seguridad y exime a Guareschi y Paz SA de CV de cualquier pérdida en su cuenta y no tendrá derecho a reclamar, por un eventual intrusismo en su cuenta.<br>' +
          'Aun cuando Guareschi y Paz SA de CV queda eximido de una suplantación de identidad o cualquier tipo de piratería en el acceso a su cuenta, la compañía se compromete a velar por mantener y mejorar los sistemas de seguridad y de detección de fraude para evitar, en la medida de nuestras posibilidades, cualquier tipo de intromisión en las cuentas de la plataforma Guareschi y Paz SA de CV, sin que este compromiso represente o constituya en si ninguna garantía.<br>' +
          '<strong>COOKIES</strong><br>' +
          'Las cookies son pequeñas piezas de información que son enviadas por el sitio web de su navegador.<br>' +
          'Se almacenan en el disco duro de los ordenadores y se utilizan para determinar las preferencias de los usuarios que acceden al portal electrónico www.cactusprepaid.com así como para rastrear determinados comportamientos o actividades.<br>' +
          'Las cookies permiten a los usuarios reconocer, detectar el ancho de banda que han seleccionado, identificar la información más importante, calcular el tamaño de la audiencia y medir algunos parámetros de tráfico.<br>' +
          '<strong>ACEPTACIÓN DE TERMINOS</strong><br>' +
          'Esta declaración de Confidencialidad / privacidad está sujeta a los términos y condiciones de todos los sitios web, Guareschi y Paz SA de CV se describe anteriormente, lo cual constituye un acuerdo legal entre usted y Guareschi y Paz SA de CV Si el usuario utiliza los servicios en cualquier sitio www.cactusprepaid.com, que ha leído, entendido y aceptado los términos antes expuestos. Si usted no está de acuerdo con ellos, no debe proporcionar ninguna información personal o utilizar los servicios de los sitios web de Guareschi y Paz SA de CV<br>' +
          'Cordialmente,<br>' +
          'Guareschi y Paz SA de CV<br> <br>',
      termstitle: 'Términos and Condiciones',
      terms: "TÉRMINOS LEGALES Y CONDICIONES DE USO RECONOCIMIENTO Y ACEPTACIÓN DE LAS CONDICIONES DE USO.<br>" +
          "El presente documento tiene carácter legal de contrato de servicio, ejercido éste junto a los documentos anexos: <strong>POLITICAS ANTI-BLANQUEO DE CAPITALES</strong> y <strong>POLITICAS DE PRIVACIDAD Y LIMITES DE RESPONSABILIDAD</strong>, que forman parte indivisible de los términos y condiciones legales declaradas para el sitio web <strong>cactusprepaid.com</strong><br>" +
          "Guareschi y Paz S.A. de C.V.. no actúa y no puede actuar como asesor, incluso en relación con cualquier asunto financiero, legal, de inversión, de seguros y / o fiscal.<br>" +
          "Información general. Usted es el único responsable de determinar si una transacción contemplada es apropiada para usted.<br>" +
          "Cumplimiento de la ley; impuestos<br>" +
          "Usted es responsable de cumplir con todas las leyes aplicables relacionadas con sus actividades y otros usos de los Servicios, incluidos, entre otros, las obligaciones de notificación y el pago de todos los impuestos aplicables.<br>" +
          "<strong>Verificación de identidad.</strong><br>" +
          "Usted acepta proporcionarnos la información que solicitamos para los fines de verificación de identidad y la detección de lavado de dinero, financiamiento del terrorismo, fraude o cualquier otro delito financiero y nos permite mantener un registro de dicha información. Deberá completar ciertos procedimientos de verificación antes de poder utilizar nuestros servicios.<br>" +
          "<strong>DECLARACIÓN PRELIMINAR DE LAS PARTES</strong><br>" +
          "La marca Cactus Prepaid pertenece al grupo empresarial Guareschi y Paz S.A. de C.V.. (En adelante la “Compañía”), como titular de la marca Cactus Prepaid (en adelante las menciones que se hacen de Cactus Prepaid en este documento, tendrán la misma consideración y carácter legal que las hechas a “la compañía”), como titular de este sitio web (en adelante “el sitio”), mantiene el mismo como un servicio a sus clientes. Al utilizar el Sitio o cualquier servicio actual o futuro (s) que proporcione la Compañía (en su conjunto, entiéndanse como los “Servicios”), de acuerdo con una cuenta de servicio que proporciona la compañía en el sitio web, entendiéndose ésta cuenta como el servicio prestado y sujeta a este acuerdo, el cual debe interpretarse como él (“Acuerdo de Servicio (s)”) o contrato de servicio aplicable.<br>" +
          "<strong>ACEPTACIÓN DE CONDICIONES:</strong><br>" +
          "El usuario o cliente acepta cumplir y estar sujeto a los términos y condiciones especificados en el presente acuerdo de Términos de Uso, así como las normas, reglamentos, políticas y procedimientos, con la observancia de que éstos pueden ser modificados de vez en cuando en el Sitio (colectivamente, entiéndanse como las “Condiciones”). En tal caso, la compañía emitirá un comunicado en el sitio web y vía email informando de las modificaciones producidas.<br>" +
          "Las presentes condiciones regulan el acceso y uso del Sitio, los Servicios y la información, productos, software y / o características disponibles para usted. Si está utilizando el Sitio o los Servicios en nombre de una tercera persona, usted declara que está autorizado a aceptar estos Términos en nombre de la persona o empresa titular. En el caso de cualquier violación de los Términos. Guareschi y Paz S.A. de C.V.. se reserva el derecho de buscar todos los recursos disponibles aplicables en el contrato, la disposición de las leyes, y en equidad y proporcionalidad a los posibles perjuicios causados por tales violaciones. Los Términos se aplican a todas las visitas al sitio y el uso de los Servicios, tanto ahora como en el futuro. Cualquier violación de estos términos, puede resultar en la suspensión o cancelación de su cuenta.<br>" +
          "<strong>CUMPLIMIENTO.</strong><br>" +
          "Guareschi y Paz S.A. de C.V.. se reserva el derecho, con absoluta discreción para hacer cumplir los Términos. La compañía podrá, a su sola discreción, cancelar o suspender inmediatamente: (I) los Servicios; (II) un acuerdo de servicio; y / o (III) el acceso a este sitio, si se determina que los servicios, ofertas o actividades del usuario, en el uso de la cuenta Guareschi y Paz S.A. de C.V.. violan los Términos.<br>" +
          "<strong>MODIFICACIONES EN EL SITIO.</strong><br>" +
          "Guareschi y Paz S.A. de C.V.., con su permanente afán de mejorar los sistemas y servicios hacia nuestros usuarios, se reserva el derecho a modificar o interrumpir la totalidad o parte del Sitio, temporal o permanentemente, con o sin previo aviso, al objeto de acometer las mejoras y reformas del sitio web y sus sistemas informáticos. Las modificaciones entrarán en vigor automáticamente inmediatamente después de su publicación inicial en este Sitio. Si usted se opone a cualquier modificación de las Condiciones, o renovaciones de la estructura y servicios prestados en el sitio, su único recurso es inmediato: (a) terminar el uso del Sitio y / o los Servicios; y (b) notificar a la Compañía su intención de finalizar con el uso del sitio.<br>" +
          "<strong>DERECHOS DE PROPIEDAD INTELECTUAL.</strong><br>" +
          "El sitio es propiedad de la compañía. Todos los textos, imágenes, marcas, logotipos y otros contenidos del Sitio (“Contenido del Sitio”) es propiedad de la Guareschi y Paz S.A. de C.V.. o de terceros a partir de los cuales la Guareschi y Paz S.A. de C.V.. ha obtenido el permiso. La Compañía le autoriza a ver, descargar e imprimir el Contenido del Sitio, siempre que: (I) sólo podrá hacerlo para su propio uso personal y no comercial, (salvo el material puesto a disposición por la compañía para su uso comercial, bajo el programa Freelancer de Guareschi y Paz S.A. de C.V..); (II) usted no puede copiar, publicar o redistribuir cualquier contenido del sitio sin autorización previa; (III) no se puede modificar el contenido del sitio; (IV) no podrá eliminar ningún copyright, marca comercial u otros avisos de propiedad que se han colocado en el contenido del sitio por la Compañía. Con excepción de lo expresamente permitido por encima, reproducción o redistribución del contenido del sitio, o cualquier parte del Contenido del Sitio, está estrictamente prohibido sin el consentimiento previo por escrito de la Compañía. Para solicitar permiso puede comunicarse con legal@tradecommerce.com. Usted manifiesta y garantiza que su uso del contenido del sitio será consistente con esta licencia y no infringirá ni violará los derechos de cualquier otra parte o incumplir cualquier contrato u obligación legal de cualquier otra parte.<br>" +
          "<strong>POLÍTICA DE PRIVACIDAD, RENUNCIA Y LIMITACIÓN DE RESPONSABILIDAD.</strong><br>" +
          "Usted reconoce y acepta los términos y el descargo de responsabilidad, así como las políticas sobre confidencialidad y uso de sus datos.<br>" +
          "Estas políticas y términos se incluyen en el documento <strong>POLÍTICA DE PRIVACIDAD Y DESCARGO DE RESPONSABILIDAD</strong>, el cual está disponible en la pestaña etiquetada como tal en el BOX.<br>" +
          "Estos términos de uso están sujetos a las disposiciones de las políticas de privacidad mencionadas anteriormente y referenciadas.<br> <br>"
    }
  },
  en: {
    landing: {
      fees: 'Fees',
      benefits: 'Benefits',
      contact: 'Contact',
      subtitle: 'Use your CactusCard at <strong>anytime, anywhere</strong><br>ATM`s and online stores',
      buy: 'Buy around the world',
      buy_subtitle: 'It´s a prepaid debit card accepted worldwide.',
      withdraw:'<strong>Withdraw </strong> cash at ATMs',
      withdraw_subtitle:'You can withdraw cash from any ATM anywhere.',
      pay:'<strong>Pay</strong> online',
      pay_subtitle:'Purchase products and services at any online store were MasterCard is accepted.',
      start_today: 'START TODAY!',
      plastic_or_virtual: "Plastic or virtual. It's up to you.",
      plastic: "Plastic",
      plastic_subtitle1: "Like any other regular card, you get the plastic for online, offline and ATM´s transactions.",
      plastic_subtitle2: "<b>Card will arrive</b> by mail within 10 business days ready to be activated!",
      plastic_want: "I WANT THIS CARD",
      virtual: "Virtual",
      virtual_subtitle1: "Virtual card has no physical form, which means you can only use it for online payments. You get your card details (card number, expiration date, and CVV) via email.",
      virtual_subtitle2: "You can start using your virtual card as soon as you receive it in your e-mail. Unlike plastic, there is no need to wait for delivery.",
      virtual_want: "I WANT THIS CARD",
      benefits1: '<li>No bank accounts or credit histories are required from cardholders.</li><br>' +
          '<li>Control and manage your payments through an easy and intuitive online administration console.</li><br>' +
          '<li>Funds available in just a few minutes after recharging.</li>',
      benefits2: '<li>Lower operating expenses and greater efficiency in electronic payments.</li><br>' +
          '<li>Assistance by email / phone / online chat. 24 hours.</li><br>' +
          '<li>Access to funds through ATMs and points of sale at any moment and anywhere in the world.</li>',
      benefits3: '<li>Simple and transparent payment structure.</li><br>' +
          '<li>Banking Service to receive and send intentional transfers without limits.</li><br>' +
          '<li>Balance transfer service (Paypal, Payoneer, Western Union, etc.) Consult us.</li>' +
          '<li>Possibility of own logo for Companies that require volume of Cards.</li>',
      havent_card: "Haven't got a CactusCard yet?",
      company: "Company",
      terms_label: "Terms and Conditions",
      privacy_label: "Privacy Policy",
      policy_label: "Policy Anti-money Laundering",
      help: "Help & Support",
      begin: "BEGIN TODAY",
      faq: 'FAQ',
      policytitle: 'Anti Laundering Policy',
      policy: '<strong>ANTI-MONEY LAUNDERING POLICIES</strong><br>' +
          'It could happen that you are required to confirm your identity and other personal details. Guareschi y Paz SA de CV, has special verification systems established including your bank account.<br>' +
          'The theft of data for the purposes of identity theft, intended for the financing of organized crime, is increasing, due to the large volume of information that is sent on the internet, and which in many cases is intercepted by criminals for their purposes .<br>' +
          'The countries at the forefront of the fight against money laundering are mainly the governments of the EU countries and the United States and all the organizations participating in financial services. All are decisively united in ending the generation of financial crimes, as part of the international effort to end fraudulent operations that seek to finance mafias, criminal organizations and terrorists, with the common goal of protecting the world population from terrorism and organized crime.<br>' +
          'Their cooperation is vital to the successful fight against crime and terrorism, which is usually required in most of the world\'s laws.<br>' +
          'We are required to keep a full record of all transactions along with the identifications provided.<br>' +
          'We are required to monitor any suspicious or unusual transaction of any amount.<br>' +
          'We have a legal obligation to report suspicious transactions to the Crime Agency.<br>' +
          '<strong>DECLARATION ON AML, CFT AND KYC</strong><br>' +
          'Financial institutions must be able to identify and understand the risks of money laundering and terrorist financing in order to apply preventive measures. Anti-Money Laundering (AML) and Counter Terrorist Financing (CTF) are priority areas of attention in Guareschi y Paz SA de CV.<br>' +
          'Guareschi y Paz SA de CV continuously evaluates the strength and relevance of our existing policies, as well as procedures and training for employees. Money laundering and terrorism not only harm the general public, but can also damage the stability and reputation of the financial sectors. It is in our best interest to take all reasonable steps to prevent money laundering and terrorist financing.<br>' +
          'Guareschi y Paz SA de CV, adheres to and complies to the letter with all compliance laws KYC (Know Your Client) and AML (Anti Money Laundering).<br>' +
          'Since criminals require financial services to disguise their operations and sponsor criminal activities, the Know Your Client (KYC) practices are important both to us and to the entire client base of Guareschi y Paz SA de CV<br>' +
          'Guareschi y Paz SA de CV uses specific tools to detect suspicious transactions, such as transactions that do not align with the customer\'s known profile. Detection of suspicious transactions triggers in-depth investigations of our client\'s account by our Compliance Division. Guareschi y Paz SA de CV has complete freedom to terminate an existing client relationship. The Compliance Department of Guareschi y Paz SA de CV has the power and legal arbitrariness to report suspicious transactions to the competent authorities.<br>' +
          'Money cannot be transferred to or from countries considered as non-cooperative without prior approval from the Compliance Department of Guareschi y Paz SA de CV. The approval will only be given based on appropriate documentation and the subsequent exhaustive analysis of the economic background, as well as the proper justification of the transaction in question.<br>' +
          'To help governments track down criminal organizations, narcotics traffickers, terrorists, and money laundering, Guareschi y Paz SA de CV, check frequently updated lists and databases for law enforcement.<br>' +
          'Due to the Anti-Money Laundering Policies and Anti-Terrorist Financing Policies, it is vitally important that financial institutions act to combat the risks of money laundering and assist governments in identifying potential terrorist-sponsored activities. Below we summarize some of the policies we follow at Guareschi y Paz SA de CV:<br>' +
          '- Our company has adopted international policy standards in the prevention of money laundering and against terrorist financing that requires the main office of Guareschi y Paz SA de CV, foreign branches and subsidiaries throughout the world to implement and adapt to effective programs to comply with relevant laws. The Legal & Compliance Department strives to ensure that the Policies of Guareschi y Paz SA de CV are applied appropriately.<br>' +
          '-We have established appropriate AML controls, regulations and compliance procedures. Guareschi y Paz SA de CV, has established procedures and policies regarding "Know Your Client". We verify the identity of the client through documents, data or information.<br>' +
          'We have developed and continue to update the regulatory framework and procedures that meet or exceed applicable legal and regulatory requirements, and we continually educate our employees;<br>' +
          'All these measures are carried out in accordance with the provisions of international laws and treaties, along with internationally recognized financial standards and regulations.<br>' +
          'We appreciate your esteemed collaboration and we apologize for any inconvenience that we may cause at any time, for compliance with the aforementioned Preventive Policies. <br> <br>',
      privacytitle: 'Privacy Policy',
      privacy: '<strong>PRIVACY POLICY AND DISCLAIMER of Guareschi y Paz SA de CV</strong><br>' +
          'This digital document is constituted as part of the agreements and terms of use of Guareschi y Paz SA de CV, and has the legal nature of a contract as an annex to the service contract (Agreement of Terms of use of the Guareschi y Paz SA de CV platform. ).<br>' +
          '<strong>IDENTITY AND ADDRESS OF THE OWNER OF SERVICES</strong><br>' +
          'Guareschi y Paz SA de CV (hereinafter referred to as “The Company” is committed to complying with a policy of confidentiality and security of the personal data of its clients (Hereinafter “client / s”, “user / s” or "Data owner") and is committed to protecting your privacy and complying with applicable legislation on the protection of personal data, Guareschi y Paz SA de CV is responsible for requesting and safeguarding your personal information.<br>' +
          'In compliance with the different applicable laws, Guareschi y Paz SA de CV declares to be the owner of a data file hosted on an encrypted firewall.<br>' +
          '<strong>PERSONAL INFORMATION REQUESTED BY Guareschi y Paz SA de CV</strong><br>' +
          'The personal data that may be requested by Guareschi y Paz SA de CV include (I) the names, numbers of the identity document or passport, addresses, telephone numbers, emails, dates of birth, payment information, digital or virtual means of payment, (II) As a user of our services, all the information that you have provided and / or provide in the future to Guareschi y Paz SA de CV for the maintenance of the business relationship, (III) the data related to the protected services provided Guareschi y Paz SA de CV in the fulfillment of its obligations, either by reason of the provision of services or sale of products, services and in general any other information that may be requested for the provision of additional services that Guareschi y Paz SA de CV may make it available in the future.<br>' +
          'It is presumed that the data provided by users is true, correct, complete, and identifies the holder of the account for services provided by Guareschi y Paz SA de CV, so it is the responsibility of the owner of the data to update them, if they suffered any variation during the service contract with Guareschi y Paz SA de CV. In this sense, the user is obliged to provide Guareschi y Paz SA de CV with the updated data as required. Similarly, Guareschi y Paz SA de CV reserves the right to take the measures it deems appropriate if the data provided by the user is false, inaccurate or incomplete.<br>' +
          '<strong>LIMITATION OF USE AND DISCLOSURE</strong><br>' +
          'The user authorizes Guareschi y Paz SA de CV to send her information about their promotions, information about special programs, offers and services by email. Occasionally, Guareschi y Paz SA de CV may send promotions from other associated companies. In this sense, Guareschi y Paz SA de CV undertakes not to disclose user data and not to sell or transfer it to third parties (*). This type of advertising is done through newsletters and promotional messages by email. The user has the right to decline to send third-party promotions and can request to unsubscribe from certain promotions that are not of interest to them, for which, Guareschi y Paz SA de CV will include a dis-subscription link in each email automatic.<br>' +
          'For the use of cookies, the "Help" button found in the toolbar of most browsers will tell you how to avoid accepting new cookies, how to have the browser notify you when you receive a new cookie or how to disable all cookies. cookies.<br>' +
          '(*) Transfer of Personal Data to third parties:<br>' +
          'Guareschi y Paz SA de CV may only transfer users\' personal data to its subsidiaries and / or affiliated companies, be they national or foreign companies.<br>' +
          'Likewise, Guareschi y Paz SA de CV may provide some data to the companies with which it has business agreements for the fulfillment of the services provided.<br>' +
          'Guareschi y Paz SA de CV will never provide your data to any competent authority or government body, unless Guareschi y Paz SA de CV is legally bound by the competent authority, even if they are requested to do so by the minister.<br>' +
          'For the fulfillment of the purposes described in the previous paragraph, the transfer or transfer of your personal data is limited to the acts, facts and / or put into practice the necessary procedures so that Guareschi y Paz SA de CV can carry out and comply with its contractual, regulatory and / or business obligations in the normal course of business. If the user has objections to their personal data being transferred and / or assigned, it is considered that they must terminate their service contract with Guareschi y Paz SA de CV, given the impossibility of complying with the obligations of service with supplier banks and others.<br>' +
          '<strong>SENSITIVE PERSONAL DATA</strong><br>' +
          'Guareschi y Paz SA de CV does not request sensitive personal data at any time. (Racial or ethnic origin, health status, genetic information, religious beliefs, union affiliation, philosophical and moral beliefs, political opinions, sexual preference or others of a private nature).<br>' +
          'MEANS TO EXERCISE THE RIGHTS OF ACCESS, RECTIFICATION, CANCELLATION OR OPPOSITION<br>' +
          'Guareschi y Paz SA de CV has the technical, material and human resources to ensure that your personal data will be treated in strict compliance with the law at any time, the user will have the right to request Guareschi y Paz SA de CV to exercise their rights in under the Law, by sending a written request to the following email address: info@cactusprepaid.com. It is understood that in order for Guareschi y Paz SA de CV to adequately and promptly address the request referred to in this article, the request of the owner must contain at least: (I) Full name of the owner of the data, (II) the identification number, (III) a brief description of the object of the request, and (IV) reasons for your request. Our response to your request will be sent within the period established by law to send by mail to the sender of the same. You acknowledge and accept that one of the purposes of the processing of your personal data is to fulfill the commercial obligations that you maintain with Guareschi y Paz SA de CV, therefore you cannot cancel or oppose the processing of personal data that may affect and / or restrict its application, unless you wish to cancel your service account with Guareschi y Paz SA de CV.<br>' +
          '<strong>AMENDMENTS TO THIS DOCUMENT</strong><br>' +
          'Guareschi y Paz SA de CV expressly reserves the right, in its sole discretion, to change, modify, add or delete parts of these privacy policies at any time. In such case, Guareschi y Paz SA de CV will publish on the website www.cactusprepid.com a notice with the modifications to be practiced, as well as a communication by email.<br>' +
          'The date of the last update will always be indicated in these privacy policies. To the extent that the user does not express his opposition to the modifications made, made known to him and continues to access and / or use, in part or in whole, the Services provided by Guareschi y Paz SA de CV, it will mean that he has accepted and consented to such changes and / or modifications.<br>' +
          'By providing your personal data to Guareschi y Paz SA de CV, you expressly acknowledge and accept that you accept these privacy policies, and that they may be modified or adjusted from time to time, and your consent for Guareschi y Paz SA de CV to continue with the treatment of your personal data in the terms described in this agreement. If you do not accept these terms, you can exercise your rights under the law, as described above<br>' +
          'It should be noted that on the website www.cactusprepaid.com, and / or on the products and / or services of Guareschi y Paz SA de CV, links to other websites may appear, to applications that use the platforms and services of other companies that They have service contracts with Guareschi y Paz SA de CV and they have their own privacy policies. If you want to know about the directives of third party supplying or associated companies, we suggest that you consult us in order to be aware of them.<br>' +
          '<strong>DATA PROTECTION AND SYSTEM SECURITY</strong><br>' +
          'The security and confidentiality of the data that users provide when contracting a service or buying a product online are protected by a secure server in the Secure Socket Layer (SSL) protocol, so that the data sent is transmitted in a secure manner. encrypted to ensure your privacy.<br>' +
          'To verify that you are in a protected environment, make sure that the encrypted security protocol that identifies with: https: // appears in the navigation bar. However, and despite having the most secure tools, the protection of data sent over the Internet cannot be guaranteed 100%, therefore, Guareschi y Paz SA de CV will constantly review the systems and try, in the As far as possible, adapt new systems and tools that improve the performance and security of the platform.<br>' +
          'The aforementioned does not imply in any way any guarantee and the user is aware that they must take extreme precautions on their local computer, regarding viruses, espionage, Phishing and password protection.<br>' +
          'Hereby the user is aware of their security obligations and exempts Guareschi y Paz SA de CV from any loss in their account and will not have the right to claim, for a possible intrusion into their account.<br>' +
          'Even when Guareschi y Paz SA de CV is exempt from identity theft or any type of piracy in accessing its account, the company is committed to ensuring that it maintains and improves security and fraud detection systems to prevent, in the extent of our possibilities, any type of interference in the accounts of the Guareschi y Paz SA de CV platform, without this commitment representing or constituting any guarantee.<br>' +
          '<strong>COOKIES</strong><br>' +
          'Cookies are small pieces of information that are sent by your browser\'s website.<br>' +
          'They are stored on the hard drive of computers and are used to determine the preferences of users who access the electronic portal www.cactusprepaid.com as well as to track certain behaviors or activities.<br>' +
          'Cookies allow users to recognize, detect the bandwidth they have selected, identify the most important information, calculate the size of the audience and measure some traffic parameters.<br>' +
          '<strong>ACCEPTANCE OF TERMS</strong><br>' +
          'This Statement of Confidentiality / Privacy is subject to the terms and conditions of all websites, Guareschi y Paz SA de CV is described above, which constitutes a legal agreement between you and Guareschi y Paz SA de CV If the user uses the services on any site www.cactusprepaid.com, which has read, understood and accepted the terms set out above. If you do not agree with them, you must not provide any personal information or use the services of the Guareschi y Paz SA de CV websites.<br>' +
          'Cordially,<br>' +
          'Guareschi y Paz SA de CV <br> <br>',
      termstitle: 'Terms and Conditions',
      terms: "<strong>LEGAL TERMS AND CONDITIONS OF USE ACKNOWLEDGMENT AND ACCEPTANCE OF THE TERMS OF USE.</strong><br>" +
          "This document has the legal nature of a service contract, exercised together with the attached documents: <strong>ANTI-MONEY LAUNDERING POLICIES</strong> and <strong>PRIVACY POLICIES AND LIMITATIONS OF LIABILITY</strong>, which are an indivisible part of the legal terms and conditions declared for the <strong>cactusprepaid.com</strong> website<br>" +
          "Guareschi y Paz S.A. de C.V .. does not act and cannot act as an advisor, including in relation to any financial, legal, investment, insurance and / or tax matter.<br>" +
          "General information. You are solely responsible for determining whether a contemplated transaction is appropriate for you.<br>" +
          "Compliance with the law; taxes<br>" +
          "You are responsible for complying with all applicable laws related to your activities and other uses of the Services, including, but not limited to, notification obligations and payment of all applicable taxes.<br>" +
          "<strong>Identity verification</strong><br>" +
          "You agree to provide us with the information we request for the purposes of identity verification and detection of money laundering, terrorist financing, fraud, or any other financial crime, and you allow us to keep a record of such information. You will need to complete certain verification procedures before you can use our services.<br>" +
          "<strong>PRELIMINARY STATEMENT OF THE PARTIES</strong><br>" +
          "The Cactus Prepaid brand belongs to the Guareschi y Paz S.A. business group. de CV. (Hereinafter the “Company”), as the owner of the Cactus Prepaid brand (hereinafter the mentions made of Cactus Prepaid in this document, will have the same consideration and legal character as those made to “the company”) , as the owner of this website (hereinafter \"the site\"), maintains it as a service to its customers. When using the Site or any current or future service (s) provided by the Company (as a whole, understood as the \"Services\"), in accordance with a service account provided by the company on the website, this account being understood as the service provided and subject to this agreement, which must be interpreted as it (“Service Agreement (s)”) or applicable service contract.<br>" +
          "<strong>ACCEPTANCE OF CONDITIONS:</strong><br>" +
          "The user or client agrees to comply and be subject to the terms and conditions specified in this Terms of Use agreement, as well as the rules, regulations, policies and procedures, with the observation that these may be modified from time to time in the Site (collectively, be understood as the \"Conditions\"). In such case, the company will issue a statement on the website and via email informing of the modifications produced.<br>" +
          "These conditions regulate access and use of the Site, the Services and the information, products, software and / or features available to you. If you are using the Site or the Services on behalf of a third person, you declare that you are authorized to accept these Terms on behalf of the person or company that owns it. In the case of any violation of the Terms. Guareschi y Paz S.A. de C.V .. reserves the right to seek all available remedies applicable in the contract, the provision of the laws, and in equity and proportionality to the possible damages caused by such violations. The Terms apply to all site visits and use of the Services, both now and in the future. Any violation of these terms may result in the suspension or cancellation of your account.<br>" +
          "<strong>COMPLIANCE.</strong><br>" +
          "Guareschi y Paz S.A. de C.V .. reserves the right, with absolute discretion to enforce the Terms. The company may, in its sole discretion, immediately cancel or suspend: (I) the Services; (II) a service agreement; and / or (III) access to this site, if it is determined that the services, offers or activities of the user, in the use of the Guareschi y Paz S.A. account. de C.V .. violate the Terms.<br>" +
          "<strong>MODIFICATIONS TO THE SITE.</strong><br>" +
          "Guareschi y Paz S.A. de CV., with its permanent desire to improve the systems and services for our users, reserves the right to modify or interrupt all or part of the Site, temporarily or permanently, with or without prior notice, in order to undertake improvements and reforms of the website and its computer systems. The modifications will take effect automatically immediately after their initial publication on this Site. If you object to any modification of the Conditions, or renewals of the structure and services provided on the site, your only recourse is immediate: (a) end the use of the Site and / or the Services; and (b) notify the Company of its intention to end use of the site.<br>" +
          "<strong>INTELLECTUAL PROPERTY RIGHTS.</strong><br>" +
          "The site is owned by the company. All the texts, images, brands, logos and other content of the Site (\"Site Content\") is the property of Guareschi y Paz S.A. de C.V .. or third parties rom which the Guareschi y Paz S.A. de C.V .. has obtained permission. The Company authorizes you to view, download and print the Site Content, provided that: (I) you can only do so for your own personal and non-commercial use, (except for the material made available by the company for commercial use, under the Freelancer de Guareschi y Paz SA de CV.); (II) you may not copy, publish or redistribute any content on the site without prior authorization; (III) the content of the site cannot be modified; (IV) may not remove any copyright, trademark or other proprietary notices that have been placed on the content of the site by the Company. Except as expressly permitted above, reproduction or redistribution of the site content, or any part of the Site Content, is strictly prohibited without the prior written consent of the Company. To request permission you can contact legal@tradecommerce.com. You represent and warrant that your use of the content on the site will be consistent with this license and will not infringe or violate the rights of any other party or breach any contract or legal obligation of any other party.<br>" +
          "<strong>PRIVACY POLICY, DISCLAIMER AND LIMITATION OF LIABILITY.</strong><br>" +
          "You acknowledge and accept the terms and the disclaimer, as well as the policies on confidentiality and use of your data.<br>" +
          "These policies and terms are included in the document <strong>PRIVACY POLICY AND DISCLAIMER OF LIABILITY</strong>, which is available in the tab labeled as such in the BOX.<br>" +
          "These terms of use are subject to the provisions of the aforementioned and referenced privacy policies.<br> <br>"

    },
  }
}

export default messages
