<!-- =========================================================================================
	File Name: App.vue
	Description: Main vue file - APP
	----------------------------------------------------------------------------------------
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Author: Pixinvent
	Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>
    import themeConfig from '@/../themeConfig.js'
    import {mapState, mapActions} from 'vuex'
    export default {
        watch: {
            '$store.state.template.theme'(val) {
                this.toggleClassInBody(val)
            },
            'alert.notificate': function () {
                if (this.alert.notificate) {
                    this.$vs.notify({
                        time: this.alert.notificate.time,
                        title: this.alert.notificate.title,
                        text: this.alert.notificate.text,
                        iconPack: this.alert.notificate.iconPack,
                        icon: this.alert.notificate.icon,
                        color: this.alert.notificate.color
                    });
                }
            },
            'user.finishOp': function () {
                if (this.user.finishOp) {
                    this.$vs.loading.close()
                    setTimeout(() => {
                        this.finishOperation(false)
                    }, 1000)
                }
            }

        },
        computed: {
            ...mapState({
                alert: state => state.alert,
                user: state => state.user,

            })
        },
        methods: {
            ...mapActions('user', ['finishOperation']),
            toggleClassInBody(className) {
                if (className == 'dark') {
                    if (document.body.className.match('theme-semi-dark')) document.body.classList.remove('theme-semi-dark')
                    document.body.classList.add('theme-dark')
                } else if (className == 'semi-dark') {
                    if (document.body.className.match('theme-dark')) document.body.classList.remove('theme-dark')
                    document.body.classList.add('theme-semi-dark')
                } else {
                    if (document.body.className.match('theme-dark')) document.body.classList.remove('theme-dark')
                    if (document.body.className.match('theme-semi-dark')) document.body.classList.remove('theme-semi-dark')
                }
            },
            handleWindowResize() {
                this.$store.commit('template/UPDATE_WINDOW_WIDTH', window.innerWidth)
            },
            handleScroll() {
                this.$store.commit('template/UPDATE_WINDOW_SCROLL_Y', window.scrollY)
            }
        },
        mounted() {
            this.toggleClassInBody(themeConfig.theme)
            this.$store.commit('template/UPDATE_WINDOW_WIDTH', window.innerWidth)
        },
        async created() {
            window.addEventListener('resize', this.handleWindowResize)
            window.addEventListener('scroll', this.handleScroll)
        },
        destroyed() {
            window.removeEventListener('resize', this.handleWindowResize)
            window.removeEventListener('scroll', this.handleScroll)
        },
    }

</script>
