/*=========================================================================================
  File Name: store.js
  Description: Vuex store
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Vuex from 'vuex'

import {template} from './template.module';
import {user} from './user.module';
import {alert} from './alert.module';

Vue.use(Vuex)


export default new Vuex.Store({
    namespaced: true,
    modules: {
        template,
        user,
        alert
    },
    strict: process.env.NODE_ENV !== 'production'
})
