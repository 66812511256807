/**
 * Created by yosbel on 17/12/18.
 */

import {userService} from '../services/user.service';
import router from '@/router'
import axios from "../helpers/auth"


const userDefaults = {
    uid         : 0,
    displayName : "",
    photoURL    : require("@/assets/images/portrait/small/avatar.jpg"),
    userRole    : ""
}
const smpp = localStorage.getItem('smpp');

const userInfoLocalStorage = JSON.parse(localStorage.getItem("smppUserInfo")) || {}


const getUserInfo = () => {
    let userInfo = {}

    // Update property in user
    Object.keys(userDefaults).forEach((key) => {
        // If property is defined in localStorage => Use that
        userInfo[key] = userInfoLocalStorage[key] ?  userInfoLocalStorage[key] : userDefaults[key]
    })

    // Include properties from localStorage
    Object.keys(userInfoLocalStorage).forEach((key) => {
        if(userInfo[key] == undefined && userInfoLocalStorage[key] != null) userInfo[key] = userInfoLocalStorage[key]
    })
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + userInfo.token
    return userInfo
}



const state = {
    activeUser : getUserInfo(),
    logged: smpp ? true : false,
    locale: 'es',
    cards: [],
    finishOp: false,
    clients:[],
    request: [],
    countries: [],
    states: []

};
const getters = {
    current: (state) => {
        return state.current
    },
    locale: (state) => {
        return state.locale
    },

}

const setters = {
    locale: (state, {val}) => {
        state.locale = val
    }
}


const actions = {
    finishOperation({commit}, st) {
        commit('finishOperat', st)
    },
    getClients({ commit}){
        userService.getClients()
            .then(
                success => {
                    commit('getClientsSuccess', success);
                }
            );
    },

    logout({commit}) {
        userService.logout();
        router.push("/pages/login");
        commit('logout');
    },
    //account
    login({dispatch, commit}, user) {
        commit('loginRequest');

        userService.login(user)
            .then(
                success => {
                    router.push({name: 'home'})
                    commit('loginSuccess', success);
                    dispatch('finishOperation', true)
                },
                error => {
                    commit('loginFailure');
                    dispatch('alert/notificateErrorByCode', error, {root: true})
                }
            )

    },
    register({dispatch, commit}, user) {
        userService.register(user)
            .then(
                success => {
                    router.push('/pages/login')
                    commit('registerSuccess', success);
                    dispatch('finishOperation', true)
                },
                error => {
                    commit('registerFailure');
                    dispatch('alert/notificateErrorByCode', error, {root: true})
                }
            )

    },
    requestCard({dispatch, commit}, payload) {
        userService.requestCard(payload)
            .then(
                success => {
                    if(!success) return;
                    dispatch('alert/notificateOK', "We have your have request. Wait for our answer.", {root: true})
                    dispatch('finishOperation', true)
                    router.push('/')
                },
                error => {
                    commit('registerFailure');
                    dispatch('alert/notificateErrorByCode', error, {root: true})
                }
            )

    },
    contact({dispatch, commit}, payload) {
        userService.contact(payload)
            .then(
                success => {
                    if(!success) return;
                    dispatch('alert/notificateOK', "We get your message. Wait for our answer.", {root: true})
                    dispatch('finishOperation', true)
                    router.push('/')
                },
                error => {
                    commit('registerFailure');
                    dispatch('alert/notificateErrorByCode', error, {root: true})
                }
            )

    },
    fetchAccessToken() {
        return new Promise((resolve) => {
            userService.refreshToken().then(response => { resolve(response) })
        })
    },
    getUserCards({dispatch, commit}){
        userService.getUserCards()
            .then(
                success => {
                    commit('userCardsSuccess', success);
                },
                error => {
                    dispatch('alert/notificateErrorByCode', error, {root: true})
                }
            );
    },getCardsRequest({dispatch, commit}){
        userService.getCardsRequest()
            .then(
                success => {
                    commit('userCardsRequestSuccess', success);
                },
                error => {
                    dispatch('alert/notificateErrorByCode', error, {root: true})
                }
            );
    },
    getCountries({commit}, param) {
        userService.getCountries(param || '')
            .then(
                success => commit('getCountriesSuccess', success)
            );
    },

    getStates({commit}, param) {
        userService.getStates(param)
            .then(
                success => commit('getStatesSuccess', success)
            );
    },


}

const mutations = {
    finishOperat(state, st) {
        state.finishOp = st;
    },

    getCountriesSuccess(state, response) {
        state.countries = response.country;

    },

    getStatesSuccess(state, response) {
        state.states = response.state;

    },

    getClientsSuccess(state, response) {
        state.clients = response.clients;

    },
    logout(state) {
        state.activeUser = {};
        state.logged = false;

    },
    //account
    loginRequest(state) {
        state.logged = false;
    },
    loginSuccess(state, payload) {
        state.logged = true;
        localStorage.setItem('smpp', JSON.stringify(payload.token));
        let userInfo = JSON.parse(localStorage.getItem("smppUserInfo")) || state.activeUser

        for (const property of Object.keys(payload)) {
            if (payload[property] != null) {
                // If some of user property is null - user default property defined in state.AppActiveUser
                state.activeUser[property] = payload[property]

                // Update key in localStorage
                userInfo[property] = payload[property]
            }



        }
        // Store data in localStorage
        localStorage.setItem("smppUserInfo", JSON.stringify(userInfo))

    },
    loginFailure(state) {
        state.logged = {};
    },
    userCardsSuccess(state, response) {
        state.cards = response.cards;
    },userCardsRequestSuccess(state, response) {
        state.request = response.request;
    }


};

export const user = {
    namespaced: true,
    state,
    getters,
    setters,
    actions,
    mutations
}
