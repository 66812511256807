/**
 * Created by yosbel on 30/03/19.
 */
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import appTranslations from './app-translations'
Vue.use(VueI18n);


const messages = appTranslations
let locale = navigator.language || navigator.userLanguage
locale = locale ? locale.split('-') : []
locale = locale[0] ? locale[0] : 'en'
locale = localStorage.getItem('locale') ? localStorage.getItem('locale') : locale
locale = locale === 'es' || locale === 'pt' || locale === 'en' ? locale : 'en'


const i18n = new VueI18n({
    locale, // global variable holding the local value
    messages, // globale variable holding translations
});


export default i18n;