// import i18n from '../i18n/i18n';

/**
 * Created by yosbel on 17/12/18.
 */
const state = {
    type: null,
    message: null,
    notificate: null
};

export function getErrorMessage(code) {
let message='';
    switch (code) {
        case 101:
            message =  'We already have your email registered'
            break;
        case 108:
            message =  'We already have your passport registered'
            break;
        case 401:
            message = 'Bad credentials';
            break;
        case 105:
            message = "La marca es obligatoria";
            break;
         case 106:
            message = "Must select a card type";
            break;
        default:
            message = 'Opps Something went wrong';
            break;
    }
    return message;
}


const actions = {
    notificateOK({commit,dispatch}, message) {
        commit('notificateSuccess', message)
        dispatch('user/finishOperation', true, { root: true })
    },
    notificateOKPath({commit,dispatch}, message) {
        commit('notificateSuccess', message)
        dispatch('user/finishOperation', true, { root: true })
    },
    notificateError({commit,dispatch}, message) {
        commit('notificateFailure', message)
        dispatch('user/finishOperation', true, { root: true })
    },
    notificateErrorByCode({commit,dispatch}, response) {
        commit('notificateFailure', getErrorMessage(response ? response: 0))
        dispatch('user/finishOperation', true, { root: true })
    },
    notificateWarning({commit,dispatch}, message) {
        commit('notificateWarn', message)
        dispatch('user/finishOperation', true, { root: true })
    },
    notificateInfo({commit,dispatch}, message) {
        commit('notificateInf', message)
        dispatch('user/finishOperation', true, { root: true })
    }

};

const mutations = {
    notificateSuccess(state, message) {
        let response={
            time: 2500,
            title: 'Excelente',
            text: message,
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'success'
        }
        state.notificate = response
    },
    notificateFailure(state, message) {
        let response={
            time: 2500,
            title: 'Error',
            text: message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
        }
        state.notificate = response
    },
    notificateWarn(state, message) {
        let response={
            time: 2500,
            title: 'Warning',
            text: message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
        }
        state.notificate = response
    },
    notificateInf(state, message) {
        let response={
            time: 2500,
            title: 'Info',
            text: message,
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'info'
        }
        state.notificate = response
    }
};

export const alert = {
    namespaced: true,
    state,
    actions,
    mutations
};
