/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  Object Strucutre:
                    path => router path
                    name => router name
                    component(lazy loading) => component to load
                    meta : {
                      rule => which user can have access (ACL)
                      breadcrumb => Add breadcrumb to specific page
                      pageTitle => Display title besides breadcrumb
                    }
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Router from 'vue-router'


Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return {x: 0, y: 0}
    },
    routes: [

        {
            // =============================================================================
            // MAIN LAYOUT ROUTES
            // =============================================================================
            path: '/admin',
            component: () => import('./layouts/main/Main.vue'),
            children: [
                // =============================================================================
                // Theme Routes
                // =============================================================================
                {
                    path: '/home',
                    name: 'home',
                    component: () => import('./views/Home.vue'),
                    meta: {
                        rule: 'super'
                    }
                },
                {
                    path: '/prepaid',
                    name: 'prepaid',
                    component: () => import('./views/Prepaid.vue'),
                    meta: {
                        rule: 'super'
                    }
                },
                {
                    path: '/card-requests',
                    name: 'card-requests-list',
                    component: () => import('./views/CardRequest.vue'),
                    meta: {
                        rule: 'super'
                    }
                }
            ],
        },
        {
            // =============================================================================
            // MAIN LAYOUT ROUTES
            // =============================================================================
            path: '/card',
            component: () => import('@/layouts/main/MainWithoutSidebar.vue'),
            children: [
                {
                    path: '/request',
                    name: 'card-request',
                    component: () => import('@/views/pages/CardRequest.vue'),
                    meta: {
                        rule: 'public'
                    }
                }
            ],
        },
        // =============================================================================
        // FULL PAGE LAYOUTS
        // =============================================================================
        {
            path: '',
            component: () => import('@/layouts/full-page/FullPage.vue'),
            children: [
                // =============================================================================
                // PAGES
                // =============================================================================
                {
                    path: '/',
                    name: 'landing',
                    component: () => import('@/views/pages/Landing.vue'),
                    meta: {
                        rule: 'public'
                    }
                },
                {
                    path: '/contact',
                    name: 'contact',
                    component: () => import('@/views/pages/Contact.vue'),
                    meta: {
                        rule: 'public'
                    }
                },
                {
                    path: '/faqs',
                    name: 'faqs',
                    component: () => import('@/views/pages/Faq.vue'),
                    meta: {
                        rule: 'public'
                    }
                },
                {
                    path: '/terms',
                    name: 'terms',
                    component: () => import('@/views/pages/Terms.vue'),
                    meta: {
                        rule: 'public'
                    }
                },
                {
                    path: '/privacy',
                    name: 'privacy',
                    component: () => import('@/views/pages/Privacy.vue'),
                    meta: {
                        rule: 'public'
                    }
                },{
                    path: '/mpolicy',
                    name: 'policy',
                    component: () => import('@/views/pages/Policy.vue'),
                    meta: {
                        rule: 'public'
                    }
                },
                {
                    path: '/fees',
                    name: 'fees',
                    component: () => import('@/views/pages/Fees.vue'),
                    meta: {
                        rule: 'public'
                    }
                },

                {
                    path: '/pages/login',
                    name: 'page-login',
                    component: () => import('@/views/pages/Login.vue'),
                    meta: {
                        rule: 'public'
                    }
                },
                {
                    path: '/pages/register',
                    name: 'page-register',
                    component: () => import('@/views/pages/Register.vue'),
                    meta: {
                        rule: 'public'
                    }
                },
                {
                    path: '/pages/confirm/:token',
                    name: 'page-confirm',
                    component: () => import('@/views/pages/Confirm.vue'),
                    meta: {
                        rule: 'public'
                    }
                },
                {
                    path: '/pages/error-404',
                    name: 'page-error-404',
                    component: () => import('@/views/pages/Error404.vue'),
                    meta: {
                        rule: 'public'
                    }
                }
            ]
        },
        // Redirect to 404 page, if no match found
        {
            path: '*',
            redirect: '/pages/error-404',
            meta: {
                rule: 'public'
            }
        }
    ],
})

router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = "none";
    }
})


export default router
